<template>
  <el-table :data="needList" style="width: 100%">
    <el-table-column label="公司名称">
      <template slot-scope="scope"> {{ scope.row.shortName }}{{ scope.row.realName }} {{ scope.row.name }}</template>
    </el-table-column>
    <el-table-column label="用户ID" prop="id"> </el-table-column>
    <el-table-column label="提交时间" prop="settleTime">
      <template slot-scope="scope">
        {{ scope.row.settleTime && scope.row.settleTime | formatTimeFilter }}
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <span v-if="scope.row.status">{{ scope.row.status == 1 ? '已通过' : '已拒绝' }}</span>
        <el-button
          v-if="!scope.row.status"
          size="mini"
          type="primary"
          round
          @click="handlePass(scope.$index, scope.row)"
          >通过</el-button
        >
        <el-button
          v-if="!scope.row.status"
          size="mini"
          type="warning"
          round
          @click="handleReject(scope.$index, scope.row)"
          >拒绝</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <!-- "id": 5,
    "businessId": 1,
    "phoneNumber": "13669236496",
    "userId": 2,
    "isSelfSubmit": 3,
    "recommenderUserId": 1,
    "isUserRecommend": 4,
    "recommenderPartnerName": "y有有有有有有有有有有有有有有有",
    "isPartnerRecommend": 2,
    "createTime": "2020-11-09T14:54:46.000+00:00",
    "isDealed": 0 -->
</template>

<script>
import {teamCheckApi} from '../../api/team'
import {employeeCheckApi} from '../../api/personal'
import {companyCheckApi} from '../../api/company'
export default {
  props: ['needList', 'type'],
  methods: {
    handlePass(index, row) {
      console.log(index, row)
      if (this.type == 1) {
        companyCheckApi({companyId: row.id, status: 1}).then(res => {
          console.log('%c 通过结果....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
          this.$emit('refreshNeedList')
        })
      }
      if (this.type == 2) {
        teamCheckApi({status: 1, teamId: row.id}).then(res => {
          console.log('%c 通过结果....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
          this.$emit('refreshNeedList')
        })
      }
      if (this.type == 3) {
        employeeCheckApi({id: row.id, status: 1}).then(res => {
          console.log('%c 通过结果....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
          this.$emit('refreshNeedList')
        })
      }
    },
    handleReject(index, row) {
      console.log(index, row)
      if (this.type == 1) {
        companyCheckApi({companyId: row.id, status: 2}).then(res => {
          console.log('%c 拒绝结果....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
          this.$emit('refreshNeedList')
        })
      }
      if (this.type == 2) {
        teamCheckApi({status: 2, teamId: row.id}).then(res => {
          console.log('%c 拒绝结果....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
          this.$emit('refreshNeedList')
        })
      }
      if (this.type == 3) {
        employeeCheckApi({id: row.id, status: 2}).then(res => {
          console.log('%c 拒绝结果....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
          this.$emit('refreshNeedList')
        })
      }
      // TODO: 发请变更处理状态  成功后
      this.$emit('refreshNeedList')
    }
  }
}
</script>

<style scoped></style>

<template>
  <el-table :data="needList" style="width: 100%">
    <el-table-column label="用户ID">
      <template slot-scope="scope"> {{ scope.row.userId }}</template>
    </el-table-column>
    <el-table-column label="订单号" prop="orderCode"> </el-table-column>
    <el-table-column label="业务类型" prop="productName">
      <template slot-scope="scope">
        {{ scope.row.business }}
        <span v-if="scope.row.business === '标准'" @click="toStandardDetail(scope.row)" class="detail_standard"
          >详细...</span
        >
      </template>
    </el-table-column>
    <el-table-column label="提交时间" prop="createTime">
      <template slot-scope="scope">
        {{ scope.row.createTime && scope.row.createTime | formatTimeFilter }}
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <span v-if="scope.row.status && type === 1">{{ scope.row.status == 1 ? '已通过' : '已拒绝' }}</span>
        <span v-if="scope.row.applyStatus && type === 2">{{ scope.row.applyStatus == 1 ? '已开票' : '已拒绝' }}</span>
        <el-button
          v-if="scope.row.status === 0 && type === 1"
          size="mini"
          type="primary"
          round
          @click="handlePass(scope.row)"
          >通过</el-button
        >
        <el-button
          v-if="scope.row.status === 0 && type === 1"
          size="mini"
          type="warning"
          round
          @click="handleReject(scope.row)"
          >拒绝</el-button
        >
        <el-button
          v-if="scope.row.applyStatus === 0 && type === 2"
          size="mini"
          type="primary"
          round
          @click="handlePass(scope.row)"
          >{{ type === 1 ? '通过' : '置已开票' }}</el-button
        >
        <el-button
          v-if="scope.row.applyStatus === 0 && type === 2"
          size="mini"
          type="warning"
          round
          @click="handleReject(scope.row)"
          >拒绝</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {orderPassed, orderRejected, invoicePassrd, innvoiceRejected} from '../../api/background'
export default {
  props: ['needList', 'type'],
  methods: {
    handlePass(row) {
      if (this.type == 1) {
        orderPassed({applyId: row.applyId}).then(res => {
          const {code} = res
          if (code === 200) {
            this.$message.success('操作成功')
            this.$emit('refreshNeedList')
          } else {
            this.$dialog.toast(res.msg)
          }
        })
      }
      if (this.type == 2) {
        invoicePassrd({status: 1, teamId: row.applyId}).then(res => {
          const {code} = res
          if (code === 200) {
            this.$message.success('操作成功')
            this.$emit('refreshNeedList')
          } else {
            this.$dialog.toast(res.msg)
          }
        })
      }
    },
    handleReject(row) {
      if (this.type == 1) {
        orderRejected({applyId: row.applyId}).then(res => {
          this.$message.success('操作成功')
          this.$emit('refreshNeedList')
        })
      }
      if (this.type == 2) {
        innvoiceRejected({applyId: row.applyId}).then(res => {
          this.$message.success('操作成功')
          this.$emit('refreshNeedList')
        })
      }
      // TODO: 发请变更处理状态  成功后
      this.$emit('refreshNeedList')
    },
    toStandardDetail(info) {
      if (info.business === '标准') {
        window.open(`${location.origin}#/quanqingStandard?orderCode=${info.orderCode}`)
        // this.$router.push({
        //   path: '/quanqingStandard',
        //   query: {
        //     orderCode: info.orderCode
        //   }
        // })
      }
    }
  }
}
</script>

<style scoped>
.detail_standard {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5ad3cd;
  line-height: 25px;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}
</style>

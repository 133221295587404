<template>
  <div>
    <el-form v-if="type == 1" ref="form" :model="form1" label-width="80px">
      <el-form-item label="标准名称">
        <el-input v-model="form1.name"></el-input>
      </el-form-item>
      <el-form-item label="标准分类">
        <el-select v-model="form1.classId" placeholder="请选择标准分类">
          <el-option v-for="item in form1ClassListEnum" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="含义">
        <el-input type="textarea" v-model="form1.meaning"></el-input>
      </el-form-item>
      <el-form-item label="不达标影响">
        <el-input type="textarea" v-model="form1.notReachInfluence"></el-input>
      </el-form-item>
      <el-form-item label="1星">
        <el-input v-model="form1.level1"></el-input>
      </el-form-item>
      <el-form-item label="2星">
        <el-input v-model="form1.level2"></el-input>
      </el-form-item>
      <el-form-item label="3星">
        <el-input v-model="form1.level3"></el-input>
      </el-form-item>
      <el-form-item label="4星">
        <el-input v-model="form1.level4"></el-input>
      </el-form-item>
      <el-form-item label="5星">
        <el-input v-model="form1.level5"></el-input>
      </el-form-item>
    </el-form>

    <el-form v-if="type == 2" ref="form" :model="form2" label-width="80px">
      <el-form-item label="产品名称">
        <el-input v-model="form2.name"></el-input>
      </el-form-item>
      <el-form-item label="所属分类">
        <el-select v-model="form2.classId" placeholder="请选择标准分类">
          <el-option v-for="item in form2ClassListEnum" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品目标">
        <el-input type="textarea" v-model="form2.target"></el-input>
      </el-form-item>
      <el-form-item label="产品能力">
        <el-input type="textarea" v-model="form2.ability"></el-input>
      </el-form-item>
      <el-form-item label="更多介绍">
        <el-input type="textarea" v-model="form2.introduction"></el-input>
      </el-form-item>
    </el-form>
    <el-button @click="onSubmit">{{ isNewItem ? '新增' : '修改' }}</el-button>
  </div>
</template>

<script>
import {
  techEditProductApi,
  techEditStandardApi,
  getTechStandardClassListApi,
  getTechProductClassListApi
} from '../../api/standard'
export default {
  props: ['type'],
  data() {
    return {
      isNewItem: true,
      form1ClassListEnum: [],
      form2ClassListEnum: [],
      form1: {
        classId: 1,
        // id: 0, TODO: 修改时候携带
        level1: 'level1',
        level2: 'level2',
        level3: 'level3',
        level4: 'level4',
        level5: 'level5',
        meaning: '含义',
        name: '标准名称',
        notReachInfluence: '不达标影响'
      },
      // 产品表单对象
      form2: {
        ability: '产品能力',
        classId: 1,
        // id: 0,// TODO: 修改时候携带
        idInProductCenter: 0,
        introduction: '更多介绍',
        isOnline: true,
        name: '产品名称',
        target: '产品目标'
      }
    }
  },
  created() {
    // TODO:  路由携带id 则为 false
    this.isNewItem
    this.getTechStandardClassList()
    this.getTechProductClassList()
  },
  methods: {
    getTechStandardClassList() {
      getTechStandardClassListApi()
        .then(res => {
          this.form1ClassListEnum = [...res.data]
        })
        .catch(err => {})
    },
    getTechProductClassList() {
      getTechProductClassListApi()
        .then(res => {
          this.form2ClassListEnum = [...res.data]
        })
        .catch(err => {})
    },
    onSubmit() {
      console.log('submit!')

      if (this.type == 1) {
        techEditStandardApi(this.form1)
          .then(res => {
            this.needList = res.data
          })
          .catch(err => {})
      } else {
        techEditProductApi(this.form2)
          .then(res => {
            this.needList = res.data
          })
          .catch(err => {})
      }
      // TODO: 添加成功提示并且 重置表单
      // this.resetForm()
    },
    resetForm() {}
  }
}
</script>

<style scoped></style>
